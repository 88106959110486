<template>
	<generic-data-editor
			title="Club Classes"
			collection-name="classes"
			:headers="headers"
			:additional-fields="additionalFields"
	/>
</template>

<script>

import GenericDataEditor from '@/components/generic-data-editor';

export default {

    name      : 'Classes',
    components: { GenericDataEditor },
    data () {
        return {
            headers: [
                { key: 'name', label: 'Name', sortable: true },
                { key: 'paymentReference', label: 'Payment Reference', sortable: true },
                { key: 'monthlyFee', label: 'Standard Monthly Fee' }
            ],
            additionalFields: [
	            {
	                key: 'paymentReference',
		            label: 'Payment Reference',
		            type: 'text',
		            description: 'Optional payment reference if different to CEMAA.'
	            },
                {
                    key: 'monthlyFee',
                    label: 'Standard Monthly Fee',
                    type: 'number',
                    description: 'Default monthly fee. Can be overridden for each student subscription.'
                }
            ]
        };
    }

};

</script>